import React, { PropsWithChildren } from "react";
import { Grid } from "@mui/material";

type LayoutColumnSize = 1 | 2 | 6 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12;

export type TwoColumnLayoutProps = {
  spacing?: number;
  className?: string;
  leftColumn?: React.ReactNode;
  rightColumn?: React.ReactNode;
  leftColumnSize?: LayoutColumnSize;
  rightColumnSize?: LayoutColumnSize;
};

export const TwoColumnLayout: React.FC<TwoColumnLayoutProps> = ({
  spacing,
  className,
  leftColumn,
  rightColumn,
  leftColumnSize = 6,
  rightColumnSize = 6,
}: PropsWithChildren<TwoColumnLayoutProps>) => {
  return (
    <Grid container spacing={spacing} className={className}>
      {leftColumn && (
        <Grid item xs={12} md={leftColumnSize}>
          {leftColumn}
        </Grid>
      )}
      {rightColumn && (
        <Grid item xs={12} md={rightColumnSize}>
          {rightColumn}
        </Grid>
      )}
    </Grid>
  );
};

export default TwoColumnLayout;
