import * as React from "react";
import { PropsWithChildren } from "react";

import Typography from "@mui/material/Typography";
import {
  ContentCard,
  ContentCardProps,
} from "../SlidingContentCard/ContentCard";

export type RichContentCardProps = {
  imgUrl?: string;
  title?: string;
  overlayContent?: JSX.Element;
  Content?: React.ReactNode;
  callToAction?: {
    label: string;
    url?: string;
    telephone?: string;
  };
  showWhichLogoOnCard?: boolean;
};

export const RichContentCard: React.FC<
  PropsWithChildren<RichContentCardProps>
> = ({
  imgUrl,
  title,
  children,
  Content,
  callToAction,
  overlayContent,
  showWhichLogoOnCard,
}: PropsWithChildren<RichContentCardProps>) => {
  const contentCardProps: ContentCardProps = {
    media: { imgUrl, title, overlayContent },
    callToAction,
    showWhichLogoOnCard,
  };
  return (
    <ContentCard {...contentCardProps}>
      {title && (
        <Typography variant="h4" data-testid="cardtitle" gutterBottom>
          {title}
        </Typography>
      )}
      {children || Content}
    </ContentCard>
  );
};
