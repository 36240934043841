import React, { PropsWithChildren, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Skeleton } from "@mui/material";
import { FHNextImage } from "../FHNextImage";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.background.paper,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    height: "100%",
    boxSizing: "border-box",
  },
  skeletonContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    borderRadius: theme.shape.borderRadius,
    transformOrigin: "none",
    transform: "none",
  },
  preLoadImageContainer: {
    height: "100%",
  },
  contentWrapper: {
    height: "100%",
  },
}));

export type ImagePaperProps = {
  imageUrl: string;
  altText: string;
  fixedHeight?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
  borderRadius?: number;
};

export const ImagePaper: React.FC<PropsWithChildren<ImagePaperProps>> = ({
  imageUrl,
  altText,
  fixedHeight,
  onClick,
  children,
  className,
  borderRadius,
}: PropsWithChildren<ImagePaperProps>) => {
  const classes = useStyles();
  const [isImgLoading, setisImgLoading] = useState(true);
  let containerAddedStyle = {};
  if (fixedHeight) {
    containerAddedStyle = {
      ...{
        height: fixedHeight,
      },
    };
  }
  return (
    imageUrl && (
      <div className={classNames(classes.root, className)}>
        {isImgLoading && (
          <Skeleton
            width="100%"
            height="100%"
            className={classes.skeletonContainer}
          />
        )}
        <FHNextImage
          src={imageUrl}
          alt={altText}
          layout="fill"
          onClick={onClick}
          style={{ borderRadius: borderRadius ? borderRadius : "5px" }}
          objectFit="cover"
          loading="lazy"
          onLoad={() => {
            setisImgLoading(false);
          }}
        />
        <div
          className={classes.contentWrapper}
          style={containerAddedStyle}
          data-testid="image-container"
        >
          {children}
        </div>
      </div>
    )
  );
};
