import React from "react";
import { makeStyles } from "@mui/styles";
import FHLink from "@components/SemanticTheme/FHLink";
import {
  FHNextImage,
  ImageLoader,
} from "@components/SemanticTheme/FHNextImage";
import { useIsClient } from "src/hooks/hooks";
import { Skeleton } from "@mui/material";
import Link from "next/link";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    gap: 16,
  },
  cardRoot: {
    flexBasis: "100%",
    backgroundColor: theme.palette.common.white,
    borderRadius: 10,
    overflow: "hidden",
  },
  mediaContainer: {
    position: "relative",
  },
  cardImage: {
    width: "100%",
    height: 0,
    paddingTop: "56.25%", //16:9
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.up("md")]: {
      paddingTop: 0,
      minHeight: 500,
    },
  },
  linkRoot: {
    fontSize: 24,
    fontFamily: theme.typography.h1.fontFamily,
    color: theme.palette.text.secondary,
    lineHeight: "32px",
    "&:hover": {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
      lineHeight: "28px",
    },
  },
  skeleton: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
}));

export type CardProps = {
  imgUrl?: string;
  callToActionText?: string;
  callToActionUrl?: string;
  imageLoader?: ImageLoader;
};

const Card: React.FC<CardProps> = ({
  imgUrl,
  callToActionText,
  callToActionUrl,
  imageLoader,
}) => {
  const classes = useStyles();
  const isClient = useIsClient();
  const [isImgLoading, setIsImgLoading] = React.useState(true);

  const handleImageLoad = () => {
    setIsImgLoading(false);
  };
  return (
    <div className={classes.root}>
      <div className={classes.cardRoot}>
        <div className={classes.mediaContainer}>
          <div className={classes.cardImage}>
            {isImgLoading && (
              <Skeleton
                variant="rectangular"
                animation="wave"
                className={classes.skeleton}
              />
            )}
            <Link href={callToActionUrl}>
              <FHNextImage
                src={imgUrl}
                alt={callToActionText}
                layout="fill"
                objectFit="cover"
                loading="lazy"
                onLoad={handleImageLoad}
                loader={imageLoader}
              />
            </Link>
          </div>
        </div>
      </div>
      {isClient && (
        <FHLink
          URL={callToActionUrl}
          title={callToActionText}
          color="primary"
          className={classes.linkRoot}
        />
      )}
    </div>
  );
};

export default Card;
