import { makeStyles } from "@mui/styles";
import React from "react";
import { RichContentCard } from "../../components/SemanticTheme/RichContentCard";
import RichText from "./RichText";
import {
  updateAbsoluteLink,
  useGenerateResponsiveImageUrl,
} from "../../components/Utils";
import { StandardCard as StandardCardProps } from "src/graphql/generated-strapi/types";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const useStyles = (showPaddingTop: boolean, showPaddingBottom: boolean) =>
  makeStyles((theme) => ({
    root: {
      height: "100%",
      padding: theme.spacing(
        showPaddingTop ? 3.5 : 0,
        0,
        showPaddingBottom ? 3.5 : 0,
      ),
      "@media (max-width:600px)": {
        padding: theme.spacing(
          showPaddingTop ? 2.5 : 0,
          0,
          showPaddingBottom ? 2.5 : 0,
        ),
        "& li": { width: "auto" },
      },
    },
    overlayTop: {
      padding: theme.spacing(1.2, 1.5),
      color: theme.palette.background.paper,
      width: "100%",
      background: `linear-gradient(to top, rgba(0,0,0,0.01) 5%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.7) 100%)`,
      display: "flex",
      alignItems: "center",
      "& svg": {
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(-0.65),
        width: 16,
      },
    },
  }))();

type StandardCard = {
  pageImgQuality?: number;
  containerImgQuality?: number;
  locationPinOnLandingPageCards?: boolean;
  showWhichLogoOnCard?: boolean;
} & StandardCardProps;

const StandardCardComponent: React.FC<StandardCard> = (props: StandardCard) => {
  const showPaddingTop = (props?.ShowTopPadding || false) as boolean;
  const showPaddingBottom = (props?.ShowBottomPadding || false) as boolean;
  const classes = useStyles(showPaddingTop, showPaddingBottom);
  const ctaText = props?.CallToActionText as string;
  const ctaURL =
    updateAbsoluteLink(props?.CallToActionLink?.data?.attributes?.PageRoute) ??
    undefined;
  const imgQuality =
    props?.ImageQuality || props?.containerImgQuality || props?.pageImgQuality;

  const cardImageWidths = {
    xs: 478,
    sm: 428,
    md: 588,
    lg: 588,
    xl: 588,
  };

  // at the moment added a new param to support background images
  // will be removed once all bg images are converted to next/image
  const imgUrl = props?.CardImage?.data?.attributes?.url
    ? useGenerateResponsiveImageUrl(
        props?.CardImage?.data?.attributes?.url,
        cardImageWidths,
        imgQuality,
        true,
      )
    : undefined;

  return (
    <>
      <div className={classes.root}>
        <RichContentCard
          title={props?.CardTitle as string}
          imgUrl={imgUrl}
          callToAction={{ label: ctaText, url: ctaURL }}
          showWhichLogoOnCard={props?.showWhichLogoOnCard}
          overlayContent={
            <>
              {props?.locationPinOnLandingPageCards && props?.ImageCaption && (
                <div className={classes.overlayTop}>
                  <span>
                    <LocationOnIcon />
                  </span>
                  <span>{props?.ImageCaption}</span>
                </div>
              )}
            </>
          }
        >
          <RichText
            contentId="text"
            contentValue={props?.CardText}
            color={undefined}
          />
        </RichContentCard>
      </div>
    </>
  );
};

export default StandardCardComponent;
