import React, { PropsWithChildren } from "react";
import classnames from "classnames";
import { makeStyles } from "@mui/styles";
import { ThemeProvider } from "@mui/material/styles";
import SemanticTheme from "../../../themes/SemanticTheme";
import SereneUI from "../../../themes/SereneUI";
import { goldplayW05MediumIt } from "../../../themes/localFonts";
import { useActiveExperimentsStateSelector } from "src/store/selectors/active-experiments.selectors";

const useStyles = (
  align: "left" | "centre" | "right",
  fontSize?: number | string,
  useSpan?: boolean,
  styleH1AsH2?: boolean,
  linksFromPumpkinToCharcoal?: boolean,
) =>
  makeStyles((theme) => ({
    container: {
      [`& > ${useSpan ? "span" : "div"}`]: {
        fontFamily: theme.typography.fontFamily,
        fontWeight: 500,
        fontSize: fontSize ? fontSize : "16px",
        color: "#2B2B2B",
        lineHeight: "24px",
        align: align,
        whiteSpace: "normal",
        "& > h1": {
          display: "block",
          fontSize: styleH1AsH2 ? "30px" : theme.typography.h1.fontSize,
          fontWeight: styleH1AsH2 ? 500 : 700,
          fontFamily: styleH1AsH2
            ? theme.typography.h2.fontFamily
            : theme.typography.h1.fontFamily,
          lineHeight: styleH1AsH2 ? "32px" : theme.typography.h1.lineHeight,
          "& strong, & b": {
            fontWeight: styleH1AsH2 ? 600 : 700,
          },
          "@media (max-width:600px)": {
            fontSize: styleH1AsH2 ? "25px" : "30px",
            lineHeight: "35px",
          },
        },
        "& > h2": {
          display: "block",
          margin: "0px 0px 10px",
          fontSize: theme.typography.h2.fontSize,
          fontFamily: theme.typography.h2.fontFamily,
          lineHeight: theme.typography.h2.lineHeight,
          fontWeight: theme.typography.h2.fontWeight,
          "& strong, & b": {
            fontWeight: 600,
            "& img": {
              maxWidth: 32,
              verticalAlign: "top",
            },
          },
          "@media (max-width:600px)": {
            fontSize: "25px",
            lineHeight: "35px",
          },
        },
        "& > h3": {
          display: "block",
          fontSize: "22px",
          fontFamily: theme.typography.h2.fontFamily,
          lineHeight: "24px",
          "& strong, & b": {
            fontWeight: 600,
            "& img": {
              maxWidth: 32,
              verticalAlign: "top",
            },
          },
          "@media (max-width:600px)": {
            fontSize: "20px",
            lineHeight: "24px",
          },
        },
        "& > h4": {
          display: "block",
          fontSize: "20px",
          fontFamily: theme.typography.h2.fontFamily,
          lineHeight: "22px",
          "& strong, & b": {
            fontWeight: 600,
          },
          "@media (max-width:600px)": {
            fontSize: "18px",
            lineHeight: "22px",
          },
        },
        "& > h5": {
          display: "block",
          fontSize: "22px",
          lineHeight: "24px",
          "& strong, & b": {
            fontFamily: theme.typography.h2.fontFamily,
            fontWeight: 600,
          },
          "@media (max-width:600px)": {
            fontSize: "20px",
          },
        }, // h5 maps to h2.alt in Rich Text, which is subtitle1 in Semantic Theme
        "& > h6": {
          display: "block",
          fontWeight: 500,
          margin: 0,
          fontSize: "20px",
          lineHeight: "26px",
          "& strong, & b": {
            fontFamily: theme.typography.h2.fontFamily,
            fontWeight: 600,
          },
          "@media (max-width:600px)": {
            fontSize: "18px",
            lineHeight: "25px",
          },
        }, // h6 maps to h4.alt in Rich Text, which is subtitle2 in Semantic Theme
        "& > p": {
          display: "block",
          fontSize: fontSize ? fontSize : "16px",
          lineHeight: "24px",
          "& strong, & b": {
            fontFamily: theme.typography.h2.fontFamily,
            fontWeight: 600,
          },
          "& > em": {
            fontFamily: goldplayW05MediumIt.style.fontFamily,
          },
        },
        "& a": {
          color: linksFromPumpkinToCharcoal
            ? theme.palette.text.primary
            : theme.palette.secondary.main,
          display: "inline",
          fontFamily: theme.typography.h2.fontFamily,
          fontWeight: 600,
          textDecoration: "underline",
        },
        "& > blockquote": {
          borderLeft: "2px solid #055845",
          display: "block",
          fontSize: "20px",
          lineHeight: "22px",
          marginInlineStart: 0,
          marginInlineEnd: 0,
          paddingLeft: "20px",
          fontFamily: theme.typography.h2.fontFamily,
          fontWeight: 600,
          "@media (max-width:600px)": {
            fontSize: "18px",
            lineHeight: "22px",
          },
        },
        "& > ul, & > ol": {
          display: "flex",
          flexDirection: "column",
          fontSize: fontSize ? fontSize : "16px",
          lineHeight: "24px",
          "& strong, & b": {
            fontFamily: theme.typography.h2.fontFamily,
            fontWeight: 600,
          },
          paddingLeft: "20px",
        },
      },
    },
    containerRoot: {
      [`& > ${useSpan ? "span" : "div"}`]: {
        "& > h2": {
          "@media (max-width:960px)": {
            fontSize: "26px",
            lineHeight: "32px",
          },
        },
        "& > p": {
          color: theme.palette.text.primary,
          fontFamily: theme.typography.body1.fontFamily,
          fontSize: theme.typography.body1.fontSize,
          fontWeight: theme.typography.body1.fontWeight,
          lineHeight: theme.typography.body1.lineHeight,
          "@media (max-width:960px)": {
            fontSize: "18px",
            lineHeight: "27px",
          },
        },
      },
    },
  }))();

export type RichContentAreaProps = {
  align?: "left" | "centre" | "right";
  fontSize?: number | string;
  className?: string;
  useSpan?: boolean;
  styleH1AsH2?: boolean;
  children?: React.ReactNode;
  isGlentressLandingPageExp?: boolean;
};

export const RichContentArea: React.FC<RichContentAreaProps> = ({
  align = "centre",
  children,
  fontSize,
  className,
  useSpan,
  styleH1AsH2 = false,
  isGlentressLandingPageExp,
}: PropsWithChildren<RichContentAreaProps>) => {
  const { activeExperiments } = useActiveExperimentsStateSelector();
  const classes = useStyles(
    align,
    fontSize,
    useSpan,
    styleH1AsH2,
    !!activeExperiments?.linksFromPumpkinToCharcoal,
  );

  return (
    <>
      <ThemeProvider
        theme={isGlentressLandingPageExp ? SereneUI : SemanticTheme}
      >
        {useSpan ? (
          <span
            className={classnames(classes.container, className, {
              [classes.containerRoot]: isGlentressLandingPageExp,
            })}
          >
            {children}
          </span>
        ) : (
          <div
            className={classnames(classes.container, className, {
              [classes.containerRoot]: isGlentressLandingPageExp,
            })}
          >
            {children}
          </div>
        )}
      </ThemeProvider>
    </>
  );
};
