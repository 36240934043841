import React from "react";
import { makeStyles } from "@mui/styles";
import { useRouter } from "next/router";
import {
  processRichText,
  useGenerateResponsiveImageUrl,
} from "../../components/Utils";
import { Grid } from "@mui/material";
import { createPathFromUrl, defaultBookingWidths } from "../../utils";
import { CabinBrochureCard } from "../../components/SemanticTheme/BrochureCard";
import { RichContentArea } from "../../components/SemanticTheme/RichContentArea";
import { makeHtml } from "../../components/Utils";
import { Typography } from "@mui/material";
import { GenericCabinTypeContent, LocationAndLink } from "@generated/types";
import { NormalizedContainer, NormalizedGrid } from "../../muiOverrides";
import { withContent } from "../../utils/TemplateWithContent";
import { defaultMaxWidths, maxImgWidth } from "src/utils/common";

const useStyles = makeStyles((theme) => ({
  grid: {
    padding: theme.spacing(1.5, 3.5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1.5),
    },
  },
  container: {
    marginBottom: `${theme.spacing(2)}px !important`,
  },
}));

interface CabinsListInCabinTabbedPageComponentProps {
  content: any;
  bslData: GenericCabinTypeContent[];
  pageImgQuality?: number;
  additionalProps?: { [key: string]: string | boolean };
}

const CabinsListInCabinTabbedPageComponent: React.FC<
  CabinsListInCabinTabbedPageComponentProps
> = (props): JSX.Element => {
  const history = useRouter();
  const { bslData: allCabins, pageImgQuality, additionalProps } = props;
  const imgQuality = props?.content?.attributes?.ImageQuality || pageImgQuality;
  const classes = useStyles();
  const isGarwnantLocation = !!additionalProps?.breconNaming;

  const generateImageUrl = (
    url: string | undefined,
    maxWidths?: maxImgWidth,
  ) => {
    return !!url
      ? useGenerateResponsiveImageUrl(url, maxWidths, imgQuality, true)
      : undefined;
  };

  return (
    <>
      <NormalizedContainer className={classes.container}>
        <NormalizedGrid container>
          <Grid container>
            {!!allCabins?.length &&
              allCabins.map((cabin, index) => (
                <Grid
                  className={classes.grid}
                  item
                  xs={12}
                  key={`cabin-index-${index}`}
                >
                  <CabinBrochureCard
                    key={cabin.cabinTypeId}
                    title={cabin.cabinTypeName as string}
                    imageGalleryCarouselProps={{
                      images: (cabin.cabinResultsPageImages || []).map(
                        (image) =>
                          ({
                            imageUrl: generateImageUrl(
                              `${image?.url}`,
                              defaultMaxWidths,
                            ),
                            smallImageUrl: generateImageUrl(
                              `${image?.url}`,
                              defaultBookingWidths,
                            ),
                            altText: image?.name,
                          }) as any,
                      ),
                    }}
                    subtitle={""}
                    description={cabin.cabinResultsPageDescription || ""}
                    features={[]}
                    dropdownLabel={cabin.dropdownTitle || undefined}
                    specifications={cabin.cabinTypeSpecifications?.map(
                      ({ url, label }: any) => ({
                        label,
                        svgIconUrl: url || "",
                      }),
                    )}
                    activities={cabin.cabinFeatures?.map(
                      ({ url, label }: any) => ({
                        label,
                        svgIconUrl: url || "",
                      }),
                    )}
                    ctaText={cabin.primaryCallToActionText || "Select"}
                    onCtaClick={(url) => {
                      if (url) {
                        history.push(createPathFromUrl(url));
                      }
                    }}
                    maxWidth={1200}
                    centered={true}
                    ctaLocationOptions={
                      cabin.locationsLinks && cabin.locationsLinks.length > 0
                        ? cabin.locationsLinks.map(
                            (location: LocationAndLink | null) => {
                              return {
                                label:
                                  isGarwnantLocation &&
                                  location?.locationName?.toLowerCase() ===
                                    "garwnant"
                                    ? "Garwnant, Brecon Beacons"
                                    : location?.locationName || "",
                                value: location?.locationLink || "",
                              };
                            },
                          )
                        : undefined
                    }
                  >
                    {cabin.cabinResultsPageDescription && (
                      <Typography variant="body1">
                        {cabin.cabinResultsPageDescription}
                      </Typography>
                    )}
                    <RichContentArea>
                      <div
                        dangerouslySetInnerHTML={makeHtml(
                          processRichText(cabin.cabinResultsPageAdditionalInfo),
                        )}
                      />
                    </RichContentArea>
                  </CabinBrochureCard>
                </Grid>
              ))}
          </Grid>
        </NormalizedGrid>
      </NormalizedContainer>
    </>
  );
};

const CabinsListInCabinTabbedPageTemplate = (props) => {
  const content = props?.content?.cabinTypesList?.data;
  const bslData = props?.bslData?.cabinTypes ?? null;
  const pageImgQuality = props?.pageImgQuality;
  const additionalProps = props?.additionalProps;
  return withContent(
    CabinsListInCabinTabbedPageComponent,
    content,
    bslData,
    pageImgQuality,
    additionalProps,
  );
};

export default CabinsListInCabinTabbedPageTemplate;
