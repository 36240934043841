import React, { PropsWithChildren } from "react";
import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ThemeProvider } from "@mui/material/styles";
import theme from "src/themes/SereneUI";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1188,
    paddingLeft: theme.spacing(2.4),
    paddingRight: theme.spacing(2.4),
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(2.4),
      marginBottom: theme.spacing(2.4),
    },
  },
}));

export type CustomContainerProps = {
  children: React.ReactNode;
};

export const CustomContainer: React.FC<CustomContainerProps> = ({
  children,
}: PropsWithChildren<CustomContainerProps>) => {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth={false} disableGutters className={classes.root}>
        {children}
      </Container>
    </ThemeProvider>
  );
};

export default CustomContainer;
