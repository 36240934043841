import { ComponentType } from "react";
import { VWODataReference } from "./AbTestIdAndPaddingContainer";

// content prop type needs to update with proper type when we found solution.
export const withContent = (
  Component: ComponentType,
  content: any,
  bslData?: any,
  pageImgQuality?: number,
  additionalProps?: { [key: string]: string | boolean },
  hideSubscribeSticky?: boolean,
  isHomePage?: boolean,
  isGlentressLandingPageExp?: boolean,
) => {
  const abTestId = content?.[0]?.attributes?.ABTestId;
  return (
    <>
      {Array.isArray(content) ? (
        content?.length > 1 ? (
          content.map((contentItems, i) => {
            return (
              <>
                {contentItems?.attributes?.ABTestId ? (
                  <VWODataReference id={contentItems?.attributes?.ABTestId}>
                    <Component
                      {...contentItems}
                      key={i}
                      bslData={bslData}
                      pageImgQuality={pageImgQuality}
                      additionalProps={additionalProps}
                      hideSubscribeSticky={hideSubscribeSticky}
                      isHomePage={isHomePage}
                      isGlentressLandingPageExp={isGlentressLandingPageExp}
                    />
                  </VWODataReference>
                ) : (
                  <Component
                    {...contentItems}
                    key={i}
                    bslData={bslData}
                    pageImgQuality={pageImgQuality}
                    additonalProps={additionalProps}
                    hideSubscribeSticky={hideSubscribeSticky}
                    isHomePage={isHomePage}
                    isGlentressLandingPageExp={isGlentressLandingPageExp}
                  />
                )}
              </>
            );
          })
        ) : !!content?.length && !!abTestId ? (
          <VWODataReference id={abTestId}>
            <Component
              {...content?.[0]}
              bslData={bslData}
              pageImgQuality={pageImgQuality}
              additionalProps={additionalProps}
              hideSubscribeSticky={hideSubscribeSticky}
              isHomePage={isHomePage}
              isGlentressLandingPageExp={isGlentressLandingPageExp}
            />
          </VWODataReference>
        ) : (
          !!content?.length && (
            <Component
              {...content?.[0]}
              bslData={bslData}
              pageImgQuality={pageImgQuality}
              additionalProps={additionalProps}
              hideSubscribeSticky={hideSubscribeSticky}
              isHomePage={isHomePage}
              isGlentressLandingPageExp={isGlentressLandingPageExp}
            />
          )
        )
      ) : content?.ABTestId ? (
        <VWODataReference id={content?.ABTestId}>
          <Component
            {...content}
            bslData={bslData}
            pageImgQuality={pageImgQuality}
            additionalProps={additionalProps}
            hideSubscribeSticky={hideSubscribeSticky}
            isHomePage={isHomePage}
            isGlentressLandingPageExp={isGlentressLandingPageExp}
          />
        </VWODataReference>
      ) : (
        <Component
          {...content}
          bslData={bslData}
          pageImgQuality={pageImgQuality}
          additionalProps={additionalProps}
          hideSubscribeSticky={hideSubscribeSticky}
          isHomePage={isHomePage}
          isGlentressLandingPageExp={isGlentressLandingPageExp}
        />
      )}
    </>
  );
};
