import React from "react";
import { makeStyles } from "@mui/styles";
import { ImageList, ImageListItem, Typography } from "@mui/material";
import Card, { CardProps } from "./Card";
import FHLink from "@components/SemanticTheme/FHLink";
import { useIsClient, useIsMobile } from "src/hooks/hooks";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(2.4),
    },
  },
  header: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
    [theme.breakpoints.up("md")]: {
      maxWidth: "58.333333%",
    },
    "& p": {
      color: theme.palette.text.primary,
    },
  },
  contentBody: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3.2),
      paddingBottom: theme.spacing(3.2),
    },
  },
  gridList: {
    display: "flex",
    overflowY: "hidden",
    padding: 0,
    margin: 0,
    flexWrap: "nowrap",
    [theme.breakpoints.up("md")]: {
      flexWrap: "wrap",
      width: "100%",
    },
    [theme.breakpoints.down("md")]: {
      gap: `16px !important`,
    },
  },
  gridListItem: {
    width: `90%`,
    [theme.breakpoints.up("md")]: {
      width: `calc(50% - 20px)`,
    },
    [theme.breakpoints.down("md")]: {
      flexShrink: 0,
    },
  },
  linkRoot: {
    fontSize: 24,
    fontFamily: theme.typography.h1.fontFamily,
    color: theme.palette.text.secondary,
    "&:hover": {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 20,
    },
  },
  actions: {
    "& $linkRoot": {
      "& svg": {
        width: 24,
        height: 24,
        marginTop: 2,
      },
    },
  },
}));

type CabinContainerCardProps = {
  title?: string;
  description?: JSX.Element | string;
  cardContent?: CardProps[];
  viewAllActionText?: string;
  viewAllActionUrl?: string;
};

const TwoColumnCabinContainerCard: React.FC<CabinContainerCardProps> = ({
  title,
  description,
  cardContent,
  viewAllActionText,
  viewAllActionUrl,
}) => {
  const classes = useStyles();
  const isMobile = useIsMobile();
  const isClient = useIsClient();

  return (
    <div className={classes.root}>
      {(title || description) && (
        <div className={classes.header}>
          {title && <Typography variant="h2">{title}</Typography>}
          {description && <Typography>{description}</Typography>}
        </div>
      )}
      {cardContent?.length > 0 && (
        <div className={classes.contentBody}>
          <ImageList
            gap={isMobile ? 16 : 40}
            cols={isMobile ? 1 : 2}
            className={classes.gridList}
          >
            {cardContent?.map((card, index) => (
              <ImageListItem
                key={`imagecard-${index}`}
                className={classes.gridListItem}
              >
                <Card
                  callToActionText={card?.callToActionText}
                  callToActionUrl={card?.callToActionUrl}
                  imgUrl={card?.imgUrl}
                />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      )}
      {viewAllActionText && viewAllActionUrl && isClient && (
        <div className={classes.actions}>
          <FHLink
            className={classes.linkRoot}
            URL={viewAllActionUrl}
            title={viewAllActionText}
            color="primary"
            hasDefaultIcon
          />
        </div>
      )}
    </div>
  );
};

export default TwoColumnCabinContainerCard;
