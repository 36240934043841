import React, { PropsWithChildren, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  Fade,
  Grid,
  IconButton,
  Skeleton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ImagePaper } from "@components/SemanticTheme/ImagePaper";
import {
  FHNextImage,
  ImageLoader,
} from "@components/SemanticTheme/FHNextImage";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const useStyles = makeStyles((theme) => ({
  root: (props: ImageGridCarouselStyleProps) => ({
    flexGrow: 1,
    maxWidth: props.maxWidth,
    margin: props.centered ? "0 auto" : "",
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(2.4),
    },
  }),
  paper: {
    backgroundSize: "100%",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
  flexibleRatioContainer: (props: ImageGridCarouselStyleProps) => ({
    overflow: "hidden",
    position: "relative",
    minHeight: props.minHeight ? props.minHeight : "auto",
  }),
  ratioContent: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%",
  },
  imageCarouselRoot: {
    position: "relative",
    [theme.breakpoints.down("md")]: {
      paddingBottom: theme.spacing(2.4),
    },
  },
  imageContainer: {
    borderRadius: 10,
    overflow: "hidden",
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    zIndex: 1,
  },
  image: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
  arrow: {
    zIndex: 2,
    position: "absolute",
    color: theme.palette.common.black,
    top: "50%",
    marginTop: -16,
    "& >span": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: theme.palette.common.white,
      padding: 0,
      color: theme.palette.common.black,
      width: 32,
      height: 32,
      borderRadius: "50%",
      "&:hover": {
        backgroundColor: theme.palette.common.white,
      },
      "& svg": {
        width: 16,
      },
    },
  },
  prevArrow: {
    left: 24,
  },
  nextArrow: {
    right: 24,
  },
  skeletonContainer: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    borderRadius: theme.shape.borderRadius,
    transformOrigin: "none",
    transform: "none",
  },
}));

export type ImageGallerySize = 1 | 2 | 6 | 3 | 4 | 5 | 7 | 8 | 9 | 10 | 11 | 12;

export type ImageCard = {
  imageUrl: string;
  altText: string;
  xs?: ImageGallerySize;
  md?: ImageGallerySize;
  preferredDesktopRatio?: { x: number; y: number };
  preferredMobileRatio?: { x: number; y: number };
};

type ImageGridCarouselStyleProps = {
  maxWidth?: string | number;
  centered?: boolean;
  minHeight?: string | number;
};

export type ImageGridCarouselProps = {
  images: ImageCard[];
  xs?: ImageGallerySize;
  md?: ImageGallerySize;
  imagePaperClassName?: string;
  selectedImageIndex?: (imageIndex: number) => void;
  imageIndex?: number;
  imageLoader?: ImageLoader;
  isDesktopCarousel?: boolean;
} & ImageGridCarouselStyleProps;

export const ImageGridCarousel: React.FC<ImageGridCarouselProps> = ({
  images,
  xs = 12,
  md = 6,
  maxWidth,
  minHeight,
  centered = true,
  imageIndex = 0,
  selectedImageIndex,
  imageLoader,
  isDesktopCarousel,
}: PropsWithChildren<ImageGridCarouselProps>) => {
  const classes = useStyles({ maxWidth, centered, minHeight });
  const theme = useTheme();
  const [imgPosition, updatePosition] = React.useState(imageIndex);
  const [allImagesLoaded, setAllImagesLoaded] = React.useState(false);
  const loadedImages = React.useRef<number[]>([]);

  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isChecked = (pos: number) => imgPosition === pos;
  const modalImages = images?.map((image) => {
    return {
      imageUrl: image.imageUrl,
      altText: image.altText,
    };
  });
  const totalImages = modalImages?.length;

  useEffect(() => {
    selectedImageIndex && selectedImageIndex(imgPosition);
  }, [imgPosition]);

  const handlePositionRight = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    updatePosition(imgPosition === totalImages - 1 ? 0 : imgPosition + 1);
  };

  const handlePositionLeft = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    updatePosition(imgPosition === 0 ? totalImages - 1 : imgPosition - 1);
  };

  const calculateRatioStyle = (index: number) => {
    const ratio = isMobile
      ? images[index].preferredMobileRatio
      : images[index].preferredDesktopRatio;
    if (ratio) {
      return { paddingTop: `${(ratio.y / ratio.x) * 100}%` };
    }
    return;
  };

  return (
    <>
      {!isMobile && !isDesktopCarousel && (
        <div className={classes.root} data-testid="Image-Grid-Carousel">
          <Grid container spacing={isMobile ? 2.4 : 4}>
            {images &&
              images.map((image, indexKey) => {
                return (
                  <Grid
                    item
                    {...{ xs: image.xs || xs, md: image.md || md }}
                    key={indexKey + "_img"}
                    className={classes.paper}
                    data-testid="image"
                  >
                    <div
                      className={`${classes.flexibleRatioContainer}`}
                      style={
                        minHeight
                          ? { paddingTop: 0 }
                          : calculateRatioStyle(indexKey)
                      }
                      data-testid="ratio-container"
                    >
                      <div className={classes.ratioContent}>
                        <ImagePaper
                          imageUrl={image.imageUrl}
                          altText={image.altText}
                          borderRadius={10}
                        />
                      </div>
                    </div>
                  </Grid>
                );
              })}
          </Grid>
        </div>
      )}
      {(isMobile || isDesktopCarousel) && (
        <div
          className={classes.imageCarouselRoot}
          data-testid="ImageCarouselRoot"
        >
          {!allImagesLoaded && (
            <Skeleton
              width="100%"
              height="100%"
              className={classes.skeletonContainer}
            />
          )}
          <div
            className={`${classes.flexibleRatioContainer}`}
            data-testid="ratio-container"
          >
            <div className={classes.imageContainer}>
              {images?.map((image, i) => (
                <React.Fragment key={`image-${i}`}>
                  <Fade timeout={500} in={isChecked(i)}>
                    <div
                      className={classes.image}
                      data-testid="galleryCarouselImage"
                    >
                      <FHNextImage
                        src={image.imageUrl}
                        alt={"title"}
                        layout="fill"
                        objectFit="cover"
                        loading="lazy"
                        loader={imageLoader}
                        onLoad={() => {
                          loadedImages?.current.push(i);
                          if (loadedImages?.current.length === images?.length) {
                            setAllImagesLoaded(true);
                          }
                        }}
                      />
                    </div>
                  </Fade>
                </React.Fragment>
              ))}
            </div>
            {allImagesLoaded && (
              <>
                {" "}
                <div className={`${classes.arrow} ${classes.prevArrow}`}>
                  <IconButton
                    aria-label="left"
                    component="span"
                    onClick={handlePositionLeft}
                    data-testid="open-button"
                  >
                    <ArrowBackIosNewIcon />
                  </IconButton>
                </div>
                <div className={`${classes.arrow} ${classes.nextArrow}`}>
                  <IconButton
                    aria-label="right"
                    component="span"
                    onClick={handlePositionRight}
                    data-testid="open-button"
                  >
                    <ArrowForwardIosIcon />
                  </IconButton>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
