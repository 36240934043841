import { createElement } from "react";
import type { GetServerSideProps, NextPage } from "next";
import { GET_COLLECTION_TYPE_USING_PAGE_REFERENCE } from "../src/graphql/gql-operations-strapi/pageReferences";
import { camal } from "radash";
import {
  experimentPageTemplates,
  pageTemplates,
  pageTemplatesWithExperiments,
} from "../src/pages";
import { capitalize } from "../src/utils/common";
import { strapiQueries } from "../src/graphql/page-queries";
import { PublicationState } from "src/graphql/generated-strapi/types";
import axios from "axios";
import dynamic from "next/dynamic";
import { NotFound404 } from "src/components/SemanticTheme/NotFound404";
import { useRouter } from "next/router";
import { captureException, captureMessage } from "@sentry/nextjs";
import NotFound from "src/pages/mapped-templates/NotFound404Template";
import { getContentFromRedis, setContentInRedis } from "src/redis-client";
import {
  addTrailingSlashToUrl,
  getBasicAuthUserNameAndPassword,
  getFeefoReviews,
  getRedisKey,
  isSSRFetchRequiredFromBSL,
} from "src/utils";
import {
  Aristotle,
  createAristotleSession,
  destroySession,
} from "src/experiments/Aristotle";
import {
  fetchDataWithPageRoute,
  getHeaderData,
  extractHeaderOrFooterRefIDnRoute,
  getFooterData,
  getSeoStructuredData,
} from "src/utils/fetchDataWithApolloClient";
import { FeefoAPIData } from "src/pages/mapped-templates/FeefoProductStarsWidget";

const DynamicStickyBookingWidget = dynamic(
  () =>
    import("../src/pages/BookingJourneySticky").then(
      (bookingWidgetTemplate) => bookingWidgetTemplate.StickyBookingWidget,
    ),
  { ssr: false },
);

const DynamicExpStickyBookingWidget = dynamic(
  () =>
    import("../src/pages/experiments/BookingJourneySticky").then(
      (bookingWidgetTemplate) => bookingWidgetTemplate.StickyBookingWidget,
    ),
  { ssr: false },
);

const DynamicStickyExtrasWidget = dynamic(
  () =>
    import("../src/pages/StickyExtrasWidgetTemplate").then(
      (bookingWidgetTemplate) => bookingWidgetTemplate.StickyExtrasWidget,
    ),
  { ssr: false },
);

const LoadPage: NextPage = (props: any) => {
  const history = useRouter();
  const { componentName, errorMessage, routeName, errorstatusCode, ...rest } =
    props;
  const errorCode =
    typeof rest?.strapiContent === "string" ? rest?.strapiContent : "";
  const pageContainsExperiments =
    (rest?.experiments && Object.keys(rest?.experiments).length > 0) || null;
  const isGlentressLandingPage =
    rest?.experiments?.isGlentressLandingPage &&
    routeName === "/locations/scotland/glentress-forest/";

  return componentName && !!rest?.strapiContent?.data ? (
    <>
      {createElement(
        pageContainsExperiments && pageTemplatesWithExperiments?.[componentName]
          ? isGlentressLandingPage
            ? experimentPageTemplates["LocationPageExperiment"]
            : pageTemplatesWithExperiments[componentName]
          : pageTemplates[componentName],
        {
          rest,
        },
      )}
      {pageContainsExperiments ? (
        !Boolean(rest?.experiments?.excludeBookingStickyWidget) && (
          <DynamicExpStickyBookingWidget experiments={rest?.experiments} />
        )
      ) : (
        <DynamicStickyBookingWidget />
      )}
      <DynamicStickyExtrasWidget />
    </>
  ) : !!parseInt(process.env.NEXT_PUBLIC_SHOW_CMS_DETAILED_ERROR_MESSAGE) ? (
    <NotFound404
      title={"Oops, something went wrong"}
      subtitle={
        errorMessage ||
        "Content not found in CMS / Page is in draft mode / Unable to fetch the Content from CMS"
      }
      buttonText={history.asPath === "/" ? "Try Again" : "Back to homepage"}
      buttonUrl={"/"}
    />
  ) : (
    <NotFound content={errorstatusCode || errorCode} />
  );
};

export default LoadPage;

export const getServerSideProps: GetServerSideProps = async (context) => {
  context.res.setHeader(
    "Cache-Control",
    "public, s-maxage=10, stale-while-revalidate=59",
  );

  const { query } = context;
  let componentName = null,
    strapiContent = null,
    headerContent = null,
    footerContent = null,
    seoStructuredData = null,
    uriSegments = null,
    routeName = null,
    previewRecordId = 0,
    publicationState = PublicationState.Live,
    errorMessage = "",
    errorstatusCode = null,
    contentTypeResponse = null,
    isCollectionTypeAvailableInRedis = false,
    redisResponse = null,
    redisResponseForContent = null,
    isContentAvailableInRedisCache = false,
    bslData = null,
    experiments = null,
    referrer = context.req.headers.referer,
    headers = JSON.stringify(context.req.headers),
    developerTools = false,
    feefoAPIData = null;
  const hideSubscribeSticky =
    context.req.cookies["hideSubscribeSticky"] ?? false;
  const hideGlentressPopUp = context.req.cookies["hideGlentressPopUp"] ?? false;
  const queryData = query;
  const homePageRoute = "/";
  const isRedisCacheEnabled =
    Number(process.env.NEXT_PUBLIC_ENABLE_REDIS_CACHE) === 1;

  const auth = getBasicAuthUserNameAndPassword();

  // delete cookies when there is a flag for preview feature and
  // there is a cookie exists
  if (Number(process.env.ENABLE_PREVIEW_FEATURE) === 1)
    try {
      await axios.get(
        `${process.env.NEXT_PUBLIC_BASE_URL}api/clear-preview-cookies`,
        auth,
      );
    } catch (e: any) {
      console.log("Exception with preview cookie...");
    }

  if (context.preview && Number(process.env.ENABLE_PREVIEW_FEATURE) === 1) {
    routeName = context.previewData["routeName"];
    publicationState = context.previewData["publicationState"];
    previewRecordId = context.previewData["previewRecordId"];
  } else {
    uriSegments = (context.params?.route as string[]) ?? ([] as string[]);
    routeName =
      uriSegments.length > 0
        ? `/${uriSegments.join("/") as string}` || homePageRoute
        : homePageRoute;
  }

  if (routeName !== routeName.toLowerCase()) {
    routeName = routeName.toLowerCase();
    return {
      redirect: {
        destination: routeName,
        permanent: true, // permanent 301 redirect
      },
    };
  }

  try {
    const aristotlePath = routeName != "/" ? routeName.slice(1) : routeName;
    const aristotle: Aristotle = await createAristotleSession(
      context.req,
      context.res,
      `${process.env.NEXT_PUBLIC_BASE_URL}${aristotlePath}`,
    );
    aristotle.runPageEvents(aristotlePath);
    aristotle.runPageExperiments(aristotlePath);

    experiments = aristotle.getPageExperimentProps();
    developerTools = aristotle.isDeveloperToolsEnabled(context.query);
    // destroy session.
    if (context.req.url.startsWith("/_next/data")) {
      destroySession(context.req, context.res);
    }
  } catch (error) {
    console.warn(error);
  }

  if (routeName.charAt(routeName.length - 1) !== "/") {
    routeName = addTrailingSlashToUrl(routeName as string);
  }

  try {
    if (isRedisCacheEnabled) {
      redisResponse = await getContentFromRedis(
        getRedisKey("collection", routeName),
      );

      isCollectionTypeAvailableInRedis = redisResponse?.status;
    }

    if (!isCollectionTypeAvailableInRedis) {
      contentTypeResponse = await fetchDataWithPageRoute(
        GET_COLLECTION_TYPE_USING_PAGE_REFERENCE,
        routeName,
      );
    } else {
      contentTypeResponse = JSON.parse(redisResponse?.cachedData);
    }

    let contentTypeUnformatted =
      contentTypeResponse?.data?.pageReferences?.data?.[0]?.attributes
        ?.CollectionName ?? null;

    if (
      !isCollectionTypeAvailableInRedis &&
      Number(process.env.NEXT_PUBLIC_ENABLE_REDIS_CACHE) &&
      contentTypeUnformatted
    ) {
      // insert data into redis cache by making url as key
      const redisKey = getRedisKey("collection", routeName);
      setContentInRedis(redisKey, contentTypeResponse);
    }

    if (contentTypeUnformatted == null) {
      const NotFoundresponse = await fetchDataWithPageRoute(
        GET_COLLECTION_TYPE_USING_PAGE_REFERENCE,
        process.env.NEXT_PUBLIC_NOTFOUND_PAGE_URL,
      );
      contentTypeUnformatted =
        NotFoundresponse?.data?.pageReferences?.data?.[0]?.attributes
          ?.CollectionName;
    }

    //redirect to 404 with correct header
    if (contentTypeUnformatted === "page404") {
      context.res.statusCode = 404;
    }

    if (!contentTypeUnformatted)
      return {
        props: {
          componentName,
          routeName,
          strapiContent,
          bslData: bslData,
          experiments: experiments ?? null,
          referrer: referrer ?? null,
          headers: headers ?? null,
          developerTools,
          queryData,
          hideSubscribeSticky,
          hideGlentressPopUp,
        },
      };

    const contentTypeFormatted = camal(
      contentTypeUnformatted.split("-").join(" "),
    );

    componentName = capitalize(contentTypeFormatted);

    // get content from redis cache
    if (isRedisCacheEnabled) {
      const isGlentressLandingPage = experiments?.isGlentressLandingPage;
      const isGlentressLocationRoute =
        routeName === "/locations/scotland/glentress-forest/";
      const pageRouteName =
        isGlentressLandingPage && isGlentressLocationRoute
          ? `${routeName}FR-635/`
          : routeName;
      redisResponseForContent = await getContentFromRedis(
        getRedisKey("content", pageRouteName),
      );

      isContentAvailableInRedisCache = redisResponseForContent?.status;

      if (redisResponseForContent?.status) {
        // Get the headerRefId from redis content
        const { id: headerRefId, route: headerRoute } =
          extractHeaderOrFooterRefIDnRoute(
            JSON.parse(redisResponseForContent?.cachedData),
            "HeaderRef",
          );

        // Get the header content from Redis by passing the headerRefId
        const headerDataFromRedis = await getContentFromRedis(
          getRedisKey("collection", headerRoute),
        );

        // Get the footerRefId from redis content
        const { id: footerRefId, route: footerRoute } =
          extractHeaderOrFooterRefIDnRoute(
            JSON.parse(redisResponseForContent?.cachedData),
            "FooterRef",
          );
        // Get the footer content from Redis by passing the footerRefId
        const footerDataFromRedis = await getContentFromRedis(
          getRedisKey("collection", footerRoute),
        );

        //Get the SeoStructuredData from redis content
        const seoStructuredDataFromRedis = await getContentFromRedis(
          getRedisKey("collection", "__seo-structured-data__"),
        );

        // Check the header, footer and SeoStructuredData content in redis and if not availabe fetch the content from CMS and insert into redis.
        if (!headerDataFromRedis?.status) {
          const headerDataFromCMS = await getHeaderData(headerRefId);
          setContentInRedis(
            getRedisKey("collection", headerRoute),
            headerDataFromCMS,
          );
        }
        if (!footerDataFromRedis?.status) {
          const footerDataFromCMS = await getFooterData(footerRefId);
          setContentInRedis(
            getRedisKey("collection", footerRoute),
            footerDataFromCMS,
          );
        }
        if (!seoStructuredDataFromRedis?.status) {
          const seoStructuredDataFromCMS = await getSeoStructuredData();
          setContentInRedis(
            getRedisKey("collection", "__seo-structured-data__"),
            seoStructuredDataFromCMS,
          );
        }
      }
    }

    if (!isContentAvailableInRedisCache) {
      const isGlentressLandingPage = experiments?.isGlentressLandingPage;
      const isGlentressLocationRoute =
        routeName === "/locations/scotland/glentress-forest/";

      strapiContent =
        isGlentressLandingPage && isGlentressLocationRoute
          ? await strapiQueries["locationPageExperiment"](
              publicationState,
              `${routeName}FR-635/`,
              previewRecordId,
            )
          : await strapiQueries[contentTypeFormatted](
              publicationState,
              routeName,
              previewRecordId,
            );

      // Get the headerRefId from CMS content
      const { id: headerRefId, route: headerRoute } =
        extractHeaderOrFooterRefIDnRoute(strapiContent, "HeaderRef");

      // Get the header content from Redis by passing the headerRefId
      const headerDataFromRedis = await getContentFromRedis(
        getRedisKey("collection", headerRoute),
      );

      // Get the footerRefId from CMS content
      const { id: footerRefId, route: footerRoute } =
        extractHeaderOrFooterRefIDnRoute(strapiContent, "FooterRef");

      // Get the footer content from Redis by passing the footerRefId
      const footerDataFromRedis = await getContentFromRedis(
        getRedisKey("collection", footerRoute),
      );

      //Get the SeoStructuredData from redis content
      const seoStructuredDataFromRedis = await getContentFromRedis(
        getRedisKey("collection", "__seo-structured-data__"),
      );

      // Check header content available in redis and if not available fetch from CMS
      if (!headerDataFromRedis?.status) {
        const getHeaderDataFromCMS = await getHeaderData(headerRefId);
        headerContent = getHeaderDataFromCMS;
        setContentInRedis(
          getRedisKey("collection", headerRoute),
          getHeaderDataFromCMS,
        );
      } else {
        headerContent = JSON.parse(headerDataFromRedis?.cachedData);
      }

      // Check footer content available in redis and if not available fetch from CMS
      if (!footerDataFromRedis?.status) {
        const getFooterDataFromCMS = await getFooterData(footerRefId);
        footerContent = getFooterDataFromCMS;
        setContentInRedis(
          getRedisKey("collection", footerRoute),
          getFooterDataFromCMS,
        );
      } else {
        footerContent = JSON.parse(footerDataFromRedis?.cachedData);
      }

      // Check SeoStructuredData content available in redis and if not available fetch from CMS
      if (!seoStructuredDataFromRedis?.status) {
        const getSeoStructuredDataFromCMS = await getSeoStructuredData();
        seoStructuredData = getSeoStructuredDataFromCMS;
        setContentInRedis(
          getRedisKey("collection", "__seo-structured-data__"),
          getSeoStructuredDataFromCMS,
        );
      } else {
        seoStructuredData = JSON.parse(seoStructuredDataFromRedis?.cachedData);
      }
    } else {
      strapiContent = JSON.parse(redisResponseForContent?.cachedData);
      // Get the headerRefId from redis content
      const { route: headerRoute } = extractHeaderOrFooterRefIDnRoute(
        strapiContent,
        "HeaderRef",
      );

      // Get the footerRefId from redis content
      const { route: footerRoute } = extractHeaderOrFooterRefIDnRoute(
        strapiContent,
        "FooterRef",
      );

      // Get the header content from Redis by passing the headerRefId
      const headerContentFromRedis = await getContentFromRedis(
        getRedisKey("collection", headerRoute),
      );

      // Get the footer content from Redis by passing the footerRefId
      const footerContentFromRedis = await getContentFromRedis(
        getRedisKey("collection", footerRoute),
      );

      //Get the SeoStructuredData from redis content
      const seoStructuredDataFromRedis = await getContentFromRedis(
        getRedisKey("collection", "__seo-structured-data__"),
      );

      if (headerContentFromRedis?.status)
        headerContent = JSON.parse(headerContentFromRedis?.cachedData);
      if (footerContentFromRedis?.status)
        footerContent = JSON.parse(footerContentFromRedis?.cachedData);
      if (seoStructuredDataFromRedis?.status)
        seoStructuredData = JSON.parse(seoStructuredDataFromRedis?.cachedData);
    }

    const extractedDataAttribute =
      strapiContent.data[Object.keys(strapiContent.data)[0]].data;

    //return page404 if pageroute is unpublished
    if (extractedDataAttribute.length == 0) {
      const NotFoundresponse = await fetchDataWithPageRoute(
        GET_COLLECTION_TYPE_USING_PAGE_REFERENCE,
        process.env.NEXT_PUBLIC_NOTFOUND_PAGE_URL,
      );
      strapiContent =
        NotFoundresponse?.data?.pageReferences?.data?.[0]?.attributes
          ?.CollectionName;
    }

    errorMessage = "";
    errorstatusCode = null;

    if (typeof strapiContent === "string")
      captureException(
        `[Strapi CMS error]: Data Received: ${strapiContent},  Route: ${routeName}, Request Params: { PublicationState: ${publicationState}, routeName: ${routeName}, previewRecordId: ${previewRecordId}, componentName: ${componentName}`,
      );
    else if (
      !isContentAvailableInRedisCache &&
      isRedisCacheEnabled &&
      !("page404" in strapiContent?.data)
    ) {
      const isGlentressLandingPage = experiments?.isGlentressLandingPage;
      const isGlentressLocationRoute =
        routeName === "/locations/scotland/glentress-forest/";
      const pageRouteName =
        isGlentressLandingPage && isGlentressLocationRoute
          ? `${routeName}FR-635/`
          : routeName;
      // insert data into redis cache by making url as key
      const redisKey = getRedisKey("content", pageRouteName);
      setContentInRedis(redisKey, strapiContent);
    }

    // fetch content from BSL for SSR
    const ssrFetchForBSLApiName: string | null = isSSRFetchRequiredFromBSL(
      componentName === "BlogHubPage" ? "/forestipedia/" : routeName,
    );
    const categoryName =
      componentName === "BlogHubPage"
        ? strapiContent?.data?.blogHubPages?.data?.[0]?.attributes?.BlogCategory
            ?.data?.attributes?.Name
        : undefined;

    if (ssrFetchForBSLApiName?.length > 0 || !!query?.caid) {
      const pathName =
        ssrFetchForBSLApiName?.length > 0
          ? ssrFetchForBSLApiName
          : "get-sykes-interstitialpage-details";

      try {
        const bslDataResponse = await axios.get(
          `${process.env.NEXT_PUBLIC_BASE_URL}api/${pathName}`,
          {
            ...auth,
            params: {
              categoryName,
              cabinId: query?.caid,
            },
          },
        );

        if (bslDataResponse?.data?.status)
          bslData = bslDataResponse?.data?.bslData;
      } catch (e: any) {
        captureMessage("Exception with BSL Data from Cabins Call");
        captureException(e);
      }
    }
    //Feefo Review Data
    const fetchReviews = async () => {
      const reviews: FeefoAPIData[] = await getFeefoReviews();
      return reviews;
    };
    if (experiments?.addReviewScore) {
      const feefoReviewData = await fetchReviews();
      feefoAPIData = feefoReviewData;
    }

    return {
      props: {
        componentName,
        routeName,
        headerContent: headerContent ?? null,
        footerContent: footerContent ?? null,
        seoStructuredData: seoStructuredData ?? null,
        strapiContent: strapiContent ?? null,
        bslData: bslData,
        errorMessage,
        errorstatusCode,
        experiments: experiments ?? null,
        referrer: referrer ?? null,
        headers: headers ?? null,
        developerTools,
        queryData,
        hideSubscribeSticky,
        feefoAPIData,
        hideGlentressPopUp,
      },
    };
  } catch (error: any) {
    errorMessage = error.message;
    errorstatusCode =
      error?.networkError?.statusCode?.toString() ||
      error?.graphQLErrors
        ?.map((err: any) => err?.extensions?.code)?.[0]
        ?.toString() ||
      "";
  }

  return {
    props: {
      componentName,
      routeName,
      strapiContent,
      headerContent,
      footerContent,
      seoStructuredData,
      bslData: bslData,
      errorMessage,
      errorstatusCode,
      experiments: experiments ?? null,
      referrer: referrer ?? null,
      headers: headers ?? null,
      developerTools,
      queryData,
      hideSubscribeSticky,
      feefoAPIData,
      hideGlentressPopUp,
    },
  };
};
