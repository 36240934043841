import React from "react";
import {
  ImageGallery,
  ImageGallerySize,
} from "../../components/SemanticTheme/ImageGallery";
import {
  maxImgWidth,
  useGenerateResponsiveImageUrl,
} from "../../components/Utils";
import { Container } from "@mui/material";
import { withContent } from "../../utils/TemplateWithContent";
import { ImageGalleryEntity } from "src/graphql/generated-strapi/types";
import { ImageGridCarousel } from "@components/SereneTheme/ImageGridCarousel";
import CustomContainer from "@components/SereneTheme/CustomContainer";
import { useIsMobile } from "src/hooks/hooks";

export type ImageGalleryComponentOverrideProps = {
  sizes: {
    xs?: ImageGallerySize;
    md?: ImageGallerySize;
    preferredDesktopRatio?: { x: number; y: number };
    preferredMobileRatio?: { x: number; y: number };
  }[];
};

type ImageGalleryComponent = {
  pageImgQuality?: number;
  additionalProps?: { [key: string]: string | boolean };
  isGlentressLandingPageExp?: boolean;
} & ImageGalleryEntity;

const ImageGalleryComponent: React.FC<ImageGalleryComponent> = (
  props: ImageGalleryComponent,
) => {
  const isMobile = useIsMobile();
  const isGlentressLandingPageExp = props?.isGlentressLandingPageExp;
  const imgQuality = props?.attributes?.ImageQuality || props?.pageImgQuality;
  const maxWidths = {
    xs: 400,
    sm: 550,
    md: 550,
    lg: 550,
    xl: 550,
  };

  const maxWidthsGridImg = {
    xs: 650,
    sm: 650,
    md: 650,
    lg: 650,
    xl: 650,
  };

  const maxWidthsDesktopCarousel = {
    xs: 1200,
    sm: 1200,
    md: 1200,
    lg: 1200,
    xl: 1200,
  };

  const generateImageUrl = (
    url: string | undefined,
    maxWidths?: maxImgWidth,
  ) => {
    if (!!url) {
      return useGenerateResponsiveImageUrl(url, maxWidths, imgQuality, true);
    }
  };
  const contentImages = props?.attributes?.GalleryImages?.data;

  const images = contentImages?.map((contentItem, i) => {
    const fullImageResp = generateImageUrl(contentItem?.attributes?.url);
    const fullImageUrl = !!fullImageResp ? fullImageResp : "";
    const thumbnailImageResp = generateImageUrl(
      contentItem?.attributes?.url,
      maxWidths,
    );
    const thumbnailUrl = !!thumbnailImageResp ? thumbnailImageResp : "";
    return {
      altText: contentItem?.attributes?.alternativeText as string,
      fullUrl: fullImageUrl,
      thumbnailUrl: thumbnailUrl,
    };
  });

  const imagesGallery: any = contentImages?.map((contentImage, index) => {
    const isDesktopCarousel =
      props?.attributes?.InDesktopShowImageGalleryAsCarousel;
    const imgMaxWidths =
      index === 0 ? maxWidthsDesktopCarousel : maxWidthsGridImg;
    const fullImageResp = generateImageUrl(
      contentImage?.attributes?.url,
      isDesktopCarousel ? maxWidthsDesktopCarousel : imgMaxWidths,
    );
    const fullImageUrl = !!fullImageResp ? fullImageResp : "";
    return {
      imageUrl: fullImageUrl,
      altText: contentImage?.attributes?.alternativeText || "",
      xs: 12,
      md: index === 0 ? 12 : 6,
      preferredDesktopRatio: { x: 21, y: 9 },
    };
  });

  return (
    <>
      {isGlentressLandingPageExp
        ? imagesGallery?.length > 0 && (
            <CustomContainer>
              <ImageGridCarousel
                images={imagesGallery}
                minHeight={isMobile ? 240 : 500}
                isDesktopCarousel={
                  props?.attributes?.InDesktopShowImageGalleryAsCarousel
                }
              />
            </CustomContainer>
          )
        : images?.length > 0 && (
            <Container>
              <ImageGallery images={images} />
            </Container>
          )}
    </>
  );
};

const ImageGalleryComponentTemplate = (props) => {
  const content = props?.content?.images?.data;
  const pageImgQuality = props?.pageImgQuality;
  const additionalProps = props?.additionalProps;
  const isGlentressLandingPageExp = props?.isGlentressLandingPageExp;
  return withContent(
    ImageGalleryComponent,
    content,
    undefined,
    pageImgQuality,
    additionalProps,
    undefined,
    undefined,
    isGlentressLandingPageExp,
  );
};
export default ImageGalleryComponentTemplate;
